body {
  font-family: Inter, Helvetica Neue, Arial, sans-serif;
}

.primary-color {
  color: #00a95a;
}

h1 {
  font-size: 30px;
  font-weight: 700;
}

h2 {
  font-size: 28px;
  font-weight: 700;
}

h3 {
  font-size: 26px;
  font-weight: 700;
}

h4 {
  font-size: 24px;
  font-weight: 700;
  color: #141a16;
}

h5 {
  font-size: 20px;
  font-weight: 700;
}

h6 {
  font-size: 18px;
  font-weight: 500;
}

p {
  font-size: 14px;
  line-height: 24px;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: #00a95a;
  text-decoration: underline;
}

input[type=checkbox] {
  accent-color: #00a95a;
}

.close-btn:hover {
  color: red;
  border-color: red;
}

/* login */
.right-col {
  background-image: url('./assets/images/background-auth.jpg');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.form-label label:not(:last-child) {
  font-size: 14px;
  font-weight: 500;
}

.form-label a {
  font-size: 15px;
}

.form-label button {
  font-size: 15px;
}

.custom-width {
  max-width: 512px;
}

.login-page .custom-line {
  width: 190px;
  color: "#b6b6b6"
}

.form-check-input:checked {
  background-color: #00a95a;
  border-color: #00a95a;
}

.form-group label {
  width: 100%;
}

/* ----- */

/* nav */
.simplebar-content {
  position: relative;
  /* min-height: 100vh; */
}
/* .simplebar-content .parent-secondary-nav {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  min-height: 20vh;
} */
.simplebar-content .parent-secondary-nav{
  bottom: 88px;
  width: 100%;
}

/* ----- */

/* signup */
input.form-control {
  height: 46px;
}

.signup-list li:not(:last-child) {
  margin-bottom: 20px;
}

.signup-list img {
  margin-right: 10px;
}

.signup-right-col {
  padding: 0 50px;
}

/* ------ */

/* reset password */
.reset-password-para {
  font-size: 16px;
  opacity: .75;
}

/* ------ */

/* code verification */
.success-alert {
  height: 35px;
  background-color: #9de7c3 !important;
  color: #2e7d32 !important;
  font-size: 12px !important;
  align-items: center;
  margin-bottom: 10px;
}

/* ------ */

/* collapaseable table */
.detail-table tbody tr {
  height: 80px;
}

.domain-table-pagination p {
  margin-bottom: 0;
}

/* ------ */

/* domain page */
.record-list li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.record-list li h6 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}

.record-list .icon-style {
  font-size: 18px;
  color: #00a95a;
}

/* ------ */

/* domain detail page */
.instruction-list li {
  margin-bottom: 10px;
}

.para-background {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f4f4f4;
  border-radius: 30px;
  padding: 2px 15px;

}

.para-style {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 600;
  margin-right: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
  overflow: hidden;
}

.copy-icon {
  font-size: 16px;
}

.para-background:hover {
  background: #ccc;
}

.MuiTooltip-popper[data-popper-placement*="top"] .css-55qhbz-MuiTooltip-tooltip {
  margin-bottom: 4px;
}

/* ------ */

/* settings page */
.key-input input#filled-adornment-password {
  padding-top: 12px !important;
}

/* ------ */

/* plans page */
.card-background {
  box-shadow: 0px 0px 10px #ccc;
}

.card-header-bg {
  background: #f7f7f7;
  border-radius: 10px 10px 0 0;
}

.gradient-background {
  background: linear-gradient(180deg, rgba(2, 0, 36, 1) 0%, rgba(4, 66, 60, 1) 0%, rgba(6, 117, 78, 1) 40%, rgba(7, 151, 90, 1) 63%, rgba(8, 179, 100, 1) 81%, rgba(9, 194, 105, 1) 94%);
  /* border-radius: 0 0 130px 130px; */
}

.plan-list {
  padding: 20px 30px;
}

.plan-list ul li {
  font-size: 14px;
}

.plan-list ul li:not(:last-child) {
  margin-bottom: 10px;
}

.list-icon {
  font-size: 20px;
}

.ribbon-2 {
  --f: 10px;
  /* control the folded part*/
  --r: 15px;
  /* control the ribbon shape */
  --t: 10px;
  /* the top offset */

  position: absolute;
  inset: var(--t) calc(-1*var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path:
    polygon(0 0, 100% 0, 100% calc(100% - var(--f)), calc(100% - var(--f)) 100%,
      calc(100% - var(--f)) calc(100% - var(--f)), 0 calc(100% - var(--f)),
      var(--r) calc(50% - var(--f)/2));
  background: #cece15;
  box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
}

/* ------ */
.payment-card ul li p {
  margin-bottom: 5px;
}

.payment-card ul li:last-child {
  margin-bottom: 10px;
}

.payment-card ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sc-beyTiQ {
  width: 100%;
}

.price-alert {
  padding: 5px 16px;
}

.price-alert .MuiAlert-message {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
}

.price-alert p {
  font-size: 12px;
}

.plan-information p {
  color: #fff;
}

.plan-information ul li {
  font-size: 13px;
}

.plan-information ul li:not(:last-child) {
  margin-bottom: 5px;
}

.plan-btn {
  background: #00a95a;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 10px 30px;
  border-radius: 6px;
  box-shadow: 2px 2px 6px #4fd999;
}

.plan-btn:hover {
  box-shadow: none;
  color: #fff;
  text-decoration: none;
}

.upgrade-btn .plan-btn {
  border-radius: 50px;
  padding: 15px 20px;
}

.upgrade-btn .plan-btn:hover {
  box-shadow: none;
  color: #fff;
  text-decoration: none;
}

.transaction-tag{
  text-decoration: none;
}
.transaction-tag:hover{
  text-decoration: none;
}

/* media-query */
@media screen and (min-width: 1600px) {
  h1 {
    font-size: 36px;
  }

  .custom-line {
    width: 230px;
  }
}

@media (min-width: 1920px) {
  .css-1oqqzyl-MuiContainer-root {
    max-width: 2030px;
  }
}

@media screen and (max-width: 1199px) {
  .right-col {
    display: none;
  }

  .signup-right-col {
    padding: 80px 50px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .btn-detail:hover .icon-spin {
    animation: App-logo-spin infinite 5s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* // Profile Modal */
.sidebar-bottom-profile{
  display: flex;
  width: 100%;
  border-top: 1px solid  rgb(236, 236, 236);
  padding: 3px;
}

.sidebar-bottom{
   cursor: 'pointer';
   margin: 4px;
   padding: 9.5px;
   width: 100%;
}

.sidebar-bottom:hover{
  cursor: 'pointer';
  background-color: #edebe4;
  border-radius: 10px;
}

.profile-sidebar-inner{
  display: flex;
}

.profile-content{
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: flex-start;
   margin-left: 19px;
}

.sidebar-profile{
  display:flex;
  flex-direction: column;
  align-items: flex-start; 
  border-right: 1px solid  rgb(236, 236, 236);
  min-width: 500px;
  min-height: 73vh;
}

.sidebar-account-info{
  width: 100%;
  border-bottom: 1px solid rgb(236, 236, 236);
}

.sidebar-account-inner{
  display: flex;
  padding: 50px 0px 40px 30px;
}
.sidebar-nav-info{
  padding: 20px 0px 0px 30px ;
}

.sidebar-navs:hover{
  background-color: #f2f5fa;
  border-radius: 10px;
}

.sidebar-navs{
   font-size: 20px;
   cursor: pointer !important;
   width: 200%;
   padding: 10px;
}

.security-tab{
  padding: 30px;
}

.subject-style{
  padding: 10px;
  font-weight:500;
}

.subject-style:hover{
  /* padding: 10px; */
  background-color: #d1eed3;
  border-radius: 10px;
}

.logout-btn:hover{
 color: #00a95a;
 cursor: pointer;
}

@media only screen and (max-width: 768px) {
.sidebar-bottom-profile{
  display: none;
}
}

.animation-box-LTR {
  animation: move-left 0.3s ease-in-out forwards;
}

@keyframes move-left {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  80% {
    opacity: 0.2;
  }
  90% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
/* 
.dialog-right-column {
  border-left: 1px solid  rgb(236, 236, 236);
} */